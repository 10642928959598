@import url(https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500&display=swap);
body {
    margin: 0;
    /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
    /*'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
    /*sans-serif;*/
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
/* Fonts */
@font-face {
    font-family: 'Visby Round CF Extra';
    src: url(/static/media/VisbyRoundCF-ExtraLight.0710a582.woff2) format('woff2'),
    url(/static/media/VisbyRoundCF-ExtraLight.b2a3ee1f.woff) format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Visby Round CF';
    src: url(/static/media/VisbyRoundCF-Heavy.d295696e.woff2) format('woff2'),
    url(/static/media/VisbyRoundCF-Heavy.d5bca55f.woff) format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Visby Round CF';
    src: url(/static/media/VisbyRoundCF-Light.8b7174ef.woff2) format('woff2'),
    url(/static/media/VisbyRoundCF-Light.20f0eba6.woff) format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Visby Round CF Demi';
    src: url(/static/media/VisbyRoundCF-DemiBold.8c3609c6.woff2) format('woff2'),
    url(/static/media/VisbyRoundCF-DemiBold.e20848df.woff) format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Visby Round CF';
    src: url(/static/media/VisbyRoundCF-Regular.73b17a8d.woff2) format('woff2'),
    url(/static/media/VisbyRoundCF-Regular.06b55b64.woff) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Visby Round CF Extra';
    src: url(/static/media/VisbyRoundCF-ExtraBold.68cbf271.woff2) format('woff2'),
    url(/static/media/VisbyRoundCF-ExtraBold.b2a31785.woff) format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Visby Round CF';
    src: url(/static/media/VisbyRoundCF-Bold.7b124e81.woff2) format('woff2'),
    url(/static/media/VisbyRoundCF-Bold.647fa183.woff) format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Visby Round CF';
    src: url(/static/media/VisbyRoundCF-Medium.25765187.woff2) format('woff2'),
    url(/static/media/VisbyRoundCF-Medium.f0101d59.woff) format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GE Dinar One';
    src: url(/static/media/GEDinarOne-Light.974fe272.woff2) format('woff2'),
    url(/static/media/GEDinarOne-Light.1d1b71a2.woff) format('woff'),
    url(/static/media/GEDinarOne-Light.69300a33.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Segoe UI';
    src: url(/static/media/SegoeUI-Semibold.b459524a.woff2) format('woff2'),
    url(/static/media/SegoeUI-Semibold.7ea7e3b7.woff) format('woff'),
    url(/static/media/SegoeUI-Semibold.3868b8ce.ttf) format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: 
      url(/static/media/Roboto-Regular.33b287bf.woff2) format('woff2'),
      url(/static/media/Roboto-Regular.35cd96f9.woff) format('woff'),
      url(/static/media/Roboto-Regular.84cbcce2.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


/* Arabic Fonts */

@font-face {
    font-family: 'Noor';
    src: url(/static/media/Noor-Light.bb44f338.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noor';
    src: url(/static/media/Noor-Bold.aa9302ac.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


/* .App {
    text-align: center;
} */
/*@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}*/

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

/*.App-link {
  color: #61dafb;
}*/

/*.MuiInput-underline:before{*/
/*  border-bottom: 1px solid #fff !important;*/
/*}*/

/*.MuiButtonBase-root {*/
/*  margin: 5px 10px;*/
/*}*/

/*.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-fullWidth.MuiInput-fullWidth.MuiInputBase-formControl.MuiInput-formControl.MuiInputBase-adornedEnd {*/
/*  color: white;*/
/*}*/

/*.MuiFormLabel-root {*/
/*  color: #fff !important;*/
/*}*/
/*.MuiInput-underline:after{*/
/*  border-bottom: 2px solid #fff !important;*/
/*}*/

/*@keyframes App-logo-spin {*/
/*  from {*/
/*    transform: rotate(0deg);*/
/*  }*/
/*  to {*/
/*    transform: rotate(360deg);*/
/*  }*/
/*}*/

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-thumb {
  background: #f6cb04;
  border-radius: 50px;
}

.map {
  position: absolute;
  width: 100%;
  height: 100%;
  /* max-width: 370px; */
  max-height: "200px";
}

