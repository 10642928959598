/* .App {
    text-align: center;
} */
/*@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}*/

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

/*.App-link {
  color: #61dafb;
}*/

/*.MuiInput-underline:before{*/
/*  border-bottom: 1px solid #fff !important;*/
/*}*/

/*.MuiButtonBase-root {*/
/*  margin: 5px 10px;*/
/*}*/

/*.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-fullWidth.MuiInput-fullWidth.MuiInputBase-formControl.MuiInput-formControl.MuiInputBase-adornedEnd {*/
/*  color: white;*/
/*}*/

/*.MuiFormLabel-root {*/
/*  color: #fff !important;*/
/*}*/
/*.MuiInput-underline:after{*/
/*  border-bottom: 2px solid #fff !important;*/
/*}*/

/*@keyframes App-logo-spin {*/
/*  from {*/
/*    transform: rotate(0deg);*/
/*  }*/
/*  to {*/
/*    transform: rotate(360deg);*/
/*  }*/
/*}*/

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-thumb {
  background: #f6cb04;
  border-radius: 50px;
}

.map {
  position: absolute;
  width: 100%;
  height: 100%;
  /* max-width: 370px; */
  max-height: "200px";
}
