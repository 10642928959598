body {
    margin: 0;
    /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
    /*'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
    /*sans-serif;*/
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500&display=swap');
/* Fonts */
@font-face {
    font-family: 'Visby Round CF Extra';
    src: url('assets/fonts/VisbyRoundCF-ExtraLight.woff2') format('woff2'),
    url('assets/fonts/VisbyRoundCF-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Visby Round CF';
    src: url('assets/fonts/VisbyRoundCF-Heavy.woff2') format('woff2'),
    url('assets/fonts/VisbyRoundCF-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Visby Round CF';
    src: url('assets/fonts/VisbyRoundCF-Light.woff2') format('woff2'),
    url('assets/fonts/VisbyRoundCF-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Visby Round CF Demi';
    src: url('assets/fonts/VisbyRoundCF-DemiBold.woff2') format('woff2'),
    url('assets/fonts/VisbyRoundCF-DemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Visby Round CF';
    src: url('assets/fonts/VisbyRoundCF-Regular.woff2') format('woff2'),
    url('assets/fonts/VisbyRoundCF-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Visby Round CF Extra';
    src: url('assets/fonts/VisbyRoundCF-ExtraBold.woff2') format('woff2'),
    url('assets/fonts/VisbyRoundCF-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Visby Round CF';
    src: url('assets/fonts/VisbyRoundCF-Bold.woff2') format('woff2'),
    url('assets/fonts/VisbyRoundCF-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Visby Round CF';
    src: url('assets/fonts/VisbyRoundCF-Medium.woff2') format('woff2'),
    url('assets/fonts/VisbyRoundCF-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GE Dinar One';
    src: url('assets/fonts/GEDinarOne-Light.woff2') format('woff2'),
    url('assets/fonts/GEDinarOne-Light.woff') format('woff'),
    url('assets/fonts/GEDinarOne-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Segoe UI';
    src: url('assets/fonts/SegoeUI-Semibold.woff2') format('woff2'),
    url('assets/fonts/SegoeUI-Semibold.woff') format('woff'),
    url('assets/fonts/SegoeUI-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: 
      url('assets/fonts/Roboto-Regular.woff2') format('woff2'),
      url('assets/fonts/Roboto-Regular.woff') format('woff'),
      url('assets/fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


/* Arabic Fonts */

@font-face {
    font-family: 'Noor';
    src: url('assets/fonts/Noor-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noor';
    src: url('assets/fonts/Noor-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

